<template>
    <div id="container">
        <div id="rule-group">
            <div class="rule">
                <i :class=" noSerialNumbers ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></i>
                <span class="rule-content">不能出现5个以上相同数字</span>
            </div>
            <div class="rule">
                <i :class=" satisfySymbolRule ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></i>
                <span class="rule-content">至少有一个大写、小写字母、数字和特殊字符</span>
            </div>
            <div class="rule">
                <span :class=" satisfyLengthRule ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></span>
                <span class="rule-content">10～16位</span>
            </div>
            <div class="rule">
                <i :class=" noBlankSpace ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></i>
                <span class="rule-content">不能有空格、换行</span>
            </div>
            <div class="rule">
                <i :class=" noChinese ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></i>
                <span class="rule-content">不能有汉字</span>
            </div>
            <div class="rule">
                <i :class=" workIdFound ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></i>
                <span class="rule-content">必须填写工号</span>
            </div>
            <div class="rule" v-if="validPassword">
                <i :class=" passwordConfirmed ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied' "></i>
                <span class="rule-content">您确认时输入的密码不同</span>
            </div>
        </div>

        <div style="display: flex; flex-direction: column; align-item: center;" v-for="msg in warningMsgs" :key="msg">
            <div style="color: red; font-weight: 500;">{{msg}}!</div>
        </div>

        <div id="workerId">
            <input class="workerId-input" placeholder="请输入账号" v-model="workerId"/>
        </div>

        <div id="password">
            <MyPasswordInput myid="signupPassword" placeholder="请输入密码" @myPasswordInputUpdated="updatePassword"/>
        </div>

        <div id="confirm" v-if="validPassword">
            <MyPasswordInput myid="signupConfirm" placeholder="请确认密码" @myPasswordInputUpdated="updateConfirm"/>
        </div>

        <div id="btn-container">
            <button id="submit" @click="signUp()">注册</button>
        </div>
    </div>
</template>

<script>
import MyPasswordInput from '../components/MyPasswordInput.vue'
import { symbolRule, cnPattern, hasBlankPattern, serialNumberPattern } from './../utils/constants'

export default {
    name : 'Signup',
    data : function() {
        return {
            password : '',
            confirm: '',
            jscode: '',
            workerId: '',
            warningMsgs: []
        }
    },

    components : {
        MyPasswordInput
    },

    computed: {
        // 1. 是否满足对于字符类型的要求？
        satisfySymbolRule() {
            return symbolRule.test(this.password)
        },
        symbolRuleIcon() {
            return this.satisfySymbolRule ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 2. 是否满足没有中文字符的要求
        noChinese() {
            return !cnPattern.test(this.password)
        },
        chineseRuleIcon() {
            return this.noChinese ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 3. 是否满足没有空格的要求
        noBlankSpace() {
            return !hasBlankPattern.test(this.password)
        },
        blankSpaceRuleIcon() {
            return this.noBlankSpace ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 4. 是否满足没有多于5个相同数字的要求
        noSerialNumbers() {
            return !serialNumberPattern.test(this.password)
        },
        serialNumberRuleIcon() {
            return this.noSerialNumbers ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 5. 是否满足长度为10到16的要求
        satisfyLengthRule() {
            return this.password.length >= 10 && this.password.length <= 16
        },
        lengthRuleIcon() {
            let satisfied = this.satisfyLengthRule
            return satisfied ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 是否是个正确的密码？
        validPassword() {
            return this.satisfySymbolRule && this.noChinese && this.noBlankSpace && this.noSerialNumbers && this.satisfyLengthRule
        },

        // 用户是否已经确认过
        passwordConfirmed() {
            return this.password === this.confirm
        },
        passwordConfirmedIcon() {
            return this.passwordConfirmed ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 是否填写了工号
        workIdFound() {
            return this.workerId.trim() != ''
        },
        workIdFoundIcon() {
            return this.workIdFound ? 'fa fa-check-circle satisfied' : 'fa fa-ban unsatisfied'
        },

        // 是否允许递交密码
        allowSubmission() {
            return this.validPassword && this.passwordConfirmed && this.workIdFound
        }
    },

    mounted() {
        let code = this.$route.query.code

        if (code) {
            this.jscode = code
            // 初始化必须将工号清空
            this.workerId = ''
            this.warningMsgs = []
        } else {
            this.$router.replace('/error?reason=' + '未获得微信授权，无法注册工资单查询密码')
        }
    },

    methods : {
        updatePassword(e) {
            this.password = e.newVal
        },

        updateConfirm(e) {
            this.confirm = e.newVal
        },

        signUp() {
            if (this.allowSubmission) {
                let encrypted = this.$md5(this.password)
                
                const reqUrl = '/api/decryption/register' + '?jsCode=' + this.jscode + '&password=' + encrypted + '&userId=' + this.workerId.trim()

                let self = this
                self.$axios
                    .get(reqUrl)
                    .then(res => {
                        if (res.data.status == 0) {
                            // 成功
                            self.successPage('密码注册成功')
                        } else {
                            // 有误
                            self.errorPage(JSON.stringify(res.data.data))
                        }
                    })
                    .catch(err => {
                        self.errorPage(JSON.stringify(err))
                    })
            } else {
                this.warningMsgs = []
                let newWarningMsgs = []
                if (!this.noSerialNumbers) {
                    newWarningMsgs.push('不能出现5个以上相同数字')
                }
                if (!this.satisfySymbolRule) {
                    newWarningMsgs.push('至少有一个大写、小写字母、数字和特殊字符')
                }
                if (!this.satisfyLengthRule) {
                    newWarningMsgs.push('密码必须为10～16位')
                }
                if (!this.noBlankSpace) {
                    newWarningMsgs.push('不能有空格、换行')
                }
                if (!this.noChinese) {
                    newWarningMsgs.push('不能有汉字')
                }
                if (!this.workIdFound) {
                    newWarningMsgs.push('必须填写工号')
                }
                if (!this.passwordConfirmed) { 
                    newWarningMsgs.push('您确认时输入的密码不同')      
                }
                this.warningMsgs = newWarningMsgs
            }
        },

        // route到error页
        errorPage(msg) {
            this.$router.replace('/error?reason=' + encodeURIComponent(msg))
        },

        // route到成功页
        successPage(msg) {
            this.$router.replace('./error?reason=' + encodeURIComponent(msg))
        }
    }
}
</script>

<style scoped>
    #rule-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: gainsboro;
    }

    .rule-content {
        font-size: small;
    }

    .satisfied {
        color: green;
    }

    .unsatisfied {
        color: red;
    }

    #workerId {
        padding: 5px;
        position: relative;
        font-size: 14px;
        display: inline-block;
    }

    #password,
    #confirm {
        padding: 5px;
    }

    #btn-container {
        padding: 5px;
    }

    #submit {
        color: #fff;
        background-color: #409eff;
        border-color: #409eff;
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 4px;
    }

    .workerId-input {
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: none;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
    }
</style>